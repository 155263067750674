const {
  VITE_API_HOST,
  VITE_ENVIRONMENT: ENVIRONMENT,
  VITE_LAUNCH_DARKLY_SDK_KEY,
  VITE_VERCEL_GIT_PULL_REQUEST_ID,
} = import.meta.env;

let API_HOST = VITE_API_HOST ?? 'http://localhost:3001';
if (VITE_VERCEL_GIT_PULL_REQUEST_ID) {
  API_HOST = `https://pm-api-pr-${VITE_VERCEL_GIT_PULL_REQUEST_ID}.herokuapp.com`;
}
const LAUNCH_DARKLY_SDK_KEY =
  VITE_LAUNCH_DARKLY_SDK_KEY ?? '621577cf301c99145d0164f2';
const AFFIRM_URL = `https://${
  ENVIRONMENT === 'production' ? 'www' : 'sandbox'
}.affirm.ca/u/loans`;

const DEFAULT_HOOK_FORM_VALIDATION_MODE: {
  mode: 'onTouched';
} = {
  mode: 'onTouched',
};

export {
  API_HOST,
  ENVIRONMENT,
  LAUNCH_DARKLY_SDK_KEY,
  AFFIRM_URL,
  DEFAULT_HOOK_FORM_VALIDATION_MODE,
};
