import {
  Card,
  LabRequisitionAttachments,
  formatDateToLongFormYear,
} from '@pm/core';
import { AttachmentFragment, Maybe } from '@pm/graphql';
import { Trans, useTranslation } from 'react-i18next';
import { ReadMore } from '../ReadMore/ReadMore';
import { Tag, TextLink } from '@pm/ui';

export type LabRequisitionCardProps = {
  requisitionType: Maybe<string>;
  sentAt: Maybe<Date>;
  attachments: AttachmentFragment[];
  readByPatientAt: string | null;
  id: string;
  notes: string | null;
};

export const LabRequisitionCard = ({
  requisitionType,
  sentAt,
  notes,
  attachments,
  readByPatientAt,
  id,
}: LabRequisitionCardProps) => {
  const { t } = useTranslation('lab-requisitions', {
    keyPrefix: 'LabRequisitions',
  });

  return (
    <Card padding="none">
      <div className="space-y-4 p-6">
        <div className="flex justify-between">
          <div>
            {requisitionType && (
              <h3 className="text-primary">
                {t(`RequisitionTypes.${requisitionType}`, {
                  defaultValue: requisitionType,
                })}
              </h3>
            )}
            {sentAt && (
              <p className="text-tertiary">
                {formatDateToLongFormYear(sentAt)}
              </p>
            )}
          </div>
          <Tag>Current</Tag>
        </div>

        {notes && (
          <div className="text-tertiary">
            <ReadMore lines={2} text={notes} />
          </div>
        )}

        <div className="flex flex-col items-start space-y-2">
          <LabRequisitionAttachments
            attachments={attachments}
            hasBeenViewed={!!readByPatientAt}
            labReqId={id}
            variant="primary"
          />
        </div>
        <p className="text-tertiary text-sm">
          <Trans t={t} i18nKey="NotifyUs">
            <TextLink to="/" />
          </Trans>
        </p>
      </div>
    </Card>
  );
};
