import { FunctionComponent, useState, Fragment } from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEnvironment } from '../../hooks/env';
import { TrashIcon } from '@heroicons/react/20/solid';
import { Popover, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import {
  Exact,
  GetConversationQuery,
  useDeleteAttachmentMutation,
} from '@pm/graphql';
import toast from 'react-hot-toast';
import type { Attachment as AttachmentType } from '@pm/graphql';
import { ApolloQueryResult } from '@apollo/client/index.js';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { StyledField } from '../StyledField';
import { Button } from '../Button';

const schema = Yup.object().shape({
  reason: Yup.string().required('Please provide a reason'),
});

export type AttachmentComponentType = {
  attachment: AttachmentType;
  handleAttachmentClick: React.MouseEventHandler<HTMLAnchorElement>;
  refetch?: (
    variables?: Partial<Exact<{ id: string }>> | undefined,
  ) => Promise<ApolloQueryResult<GetConversationQuery>>;
  removeable?: boolean;
};

export const Attachment: FunctionComponent<AttachmentComponentType> = ({
  attachment,
  handleAttachmentClick,
  refetch,
  removeable = false,
}) => {
  const { apiHost } = useEnvironment();
  const downloadUrl = `${apiHost}${attachment.downloadUrl}`;
  const [deleteAttachment] = useDeleteAttachmentMutation();

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <li className="col-span-1 flex rounded-md bg-white shadow-sm">
      <a
        href={downloadUrl}
        download
        className="bg-brand-primary-500 flex w-16 flex-shrink-0 items-center justify-center gap-3 rounded-l-md text-sm font-medium leading-5 text-white"
        aria-label="Download"
        onClick={(e) => {
          handleAttachmentClick?.(e);
        }}
      >
        <FontAwesomeIcon icon={faDownload} />
      </a>
      {removeable && (
        <div className="hover:bg-grey-200 relative flex w-11 flex-shrink-0 items-center justify-center gap-3 text-sm font-medium leading-5">
          <Popover as={Fragment}>
            <Popover.Button
              ref={setReferenceElement}
              className="h-full w-full"
              aria-label="Remove"
            >
              <TrashIcon className="m-auto h-5" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveTo="transform scale-95 opacity-0"
            >
              <Popover.Panel
                {...attributes.popper}
                ref={setPopperElement}
                style={{ ...styles.popper, zIndex: '1' }}
                className="border-1 1 text-grey-900 w-48 rounded-md bg-white p-2 shadow-lg"
              >
                {({ close }) => (
                  <Formik
                    initialValues={{ reason: '' }}
                    validationSchema={schema}
                    onSubmit={async ({ reason }) => {
                      await deleteAttachment({
                        variables: {
                          input: {
                            attachmentId: attachment.id,
                            reason,
                          },
                        },
                      }).then(() => {
                        close();
                        toast.success('Attachment deleted');
                        refetch && refetch();
                      });
                    }}
                  >
                    {({ values, resetForm }) => (
                      <Form>
                        <div className="text-grey-600 -mb-2 text-sm font-thin">{`Deleting ${attachment.filename}`}</div>
                        <StyledField name="reason" placeholder="Reason" />
                        <div className="grid grid-cols-2 gap-2">
                          <Button
                            className="bg-grey-100 text-grey-700 hover:bg-grey-200 focus:border-grey-300 active:bg-grey-200 border border-transparent transition focus:outline-none focus:ring"
                            disabled={!values.reason}
                            onClick={() => {
                              close();
                              resetForm();
                            }}
                          >
                            Cancel
                          </Button>
                          <Button type="submit" variant="critical">
                            Delete
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
      )}
      <div className="truncate p-2" title={attachment.filename}>
        {attachment.filename}
      </div>
    </li>
  );
};
