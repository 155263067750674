import { ElementRef, forwardRef } from 'react';
import { InputFieldProps } from '../types';
import { WithIcon } from '../../../types/component.types';
import { twMerge } from 'tailwind-merge';
import { Input } from '../Input';

type InputElement = ElementRef<'input'>;
type TextFieldProps = InputFieldProps &
  WithIcon & {
    ariaLabel?: string;
    prefix?: string;
    leadingMeta?: string;
    trailingMeta?: string;
  };

export const TextField = forwardRef<InputElement, TextFieldProps>(
  (
    {
      intent,
      size,
      leadingIcon,
      trailingIcon,
      leadingMeta,
      trailingMeta,
      ariaLabel,
      disabled,
      name,
      placeholder,
      onChange,
      type,
    },
    forwardedRef,
  ) => {
    return (
      <div className="relative rounded-m">
        {(leadingMeta || leadingIcon) && (
          <div
            className={twMerge(
              'pointer-events-none absolute inset-y-s left-0 flex items-center pl-s text-content-subdued',
              intent == 'error' && 'text-negative',
            )}
          >
            {leadingMeta && (
              <span
                className={twMerge(
                  'text-content-subdued sm:text-sm',
                  intent === 'error' && 'text-negative',
                )}
              >
                {leadingMeta}
              </span>
            )}
            {leadingIcon}
          </div>
        )}
        <Input
          type={type ?? 'text'}
          size={size}
          intent={intent}
          name={name}
          aria-disabled={disabled}
          aria-label={ariaLabel}
          ref={forwardedRef}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          className={twMerge(
            leadingMeta && 'pl-xl',
            leadingIcon && 'pl-xxxl',
            (trailingMeta || trailingIcon) && 'pr-xxxl',
          )}
        />
        {(trailingMeta || trailingIcon) && (
          <div className="pointer-events-none absolute inset-y-s right-m flex items-center text-content-subdued pl-s bg-surface">
            {trailingMeta && (
              <span
                className={twMerge(
                  'text-content-subdued sm:text-sm',
                  intent === 'error' && 'text-negative',
                )}
              >
                {trailingMeta}
              </span>
            )}
            {trailingIcon}
          </div>
        )}
      </div>
    );
  },
);

TextField.displayName = 'TextField';
