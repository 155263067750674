import { AnchorHTMLAttributes } from 'react';

export const composeExternalLinkAttributes = ({
  external,
  target: targetOption,
  rel: relOption,
}: Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'target' | 'rel'> & {
  external?: boolean;
}) => {
  const target = external ? targetOption ?? '_blank' : targetOption;
  const rel =
    external && !targetOption ? relOption ?? 'noopener noreferrer' : relOption;

  return {
    target,
    rel,
  } as const;
};
