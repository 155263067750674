import { VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { composeExternalLinkAttributes } from './utils';
import { NonNullableVariantProps } from '../../types/component.types';

const textLinkClasses = cva(
  'hover:text-content-link-hover active:text-brand-primary-600 focus:ring-brand-primary-200 truncate font-medium transition-all hover:underline focus:underline focus:outline-none focus:ring-2',
  {
    variants: {
      variant: {
        primary: 'text-content-link',
        secondary: 'text-primary',
        tertiary: 'text-grey-900',
      },
    },
    compoundVariants: [
      {
        variant: ['primary', 'secondary'],
        className: 'underline',
      },
    ],
    defaultVariants: {
      variant: 'primary',
    },
  },
);

type TextLinkElement = React.ElementRef<typeof Link>;
interface TextLinkProps
  extends Omit<LinkProps, 'className'>,
    NonNullableVariantProps<VariantProps<typeof textLinkClasses>> {
  external?: boolean;
}

const TextLink = React.forwardRef<TextLinkElement, TextLinkProps>(
  ({ external, variant, ...rest }, forwardedRef) => {
    const { rel, target } = composeExternalLinkAttributes({
      external,
      rel: rest.rel,
      target: rest.target,
    });

    return (
      <Link
        {...rest}
        target={target}
        rel={rel}
        className={textLinkClasses({ variant })}
        data-variant={variant}
        ref={forwardedRef}
      />
    );
  },
);

TextLink.displayName = 'TextLink';

export { TextLink };
export type { TextLinkProps };
