import { FunctionComponent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone-esm';
import { Blob } from '@pm/upload';
import clsx from 'clsx';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useEnvironment } from '../../hooks/env';
import { Uploader } from './Uploader';

// TODO: Move this to a shared types file
export interface UploadAttachmentType extends File {
  blob?: Blob;
  key?: string;
}

type AttachmentUploaderProps =
  | {
      name: string;
      label?: string;
      placeholder?: string;
      deleteButtonLabel: string;
      deleteFlagName?: string;
      existingAttachment: string | null;
      setExistingAttachment: (existingAttachment: string | null) => void;
    }
  | {
      name: string;
      label?: string;
      placeholder?: string;
      deleteButtonLabel: string;
      deleteFlagName?: never;
      existingAttachment?: never;
      setExistingAttachment?: never;
    };

export const SingleAttachmentUploader: FunctionComponent<
  AttachmentUploaderProps
> = ({
  name,
  existingAttachment,
  setExistingAttachment,
  label,
  deleteButtonLabel,
  deleteFlagName,
  placeholder,
}) => {
  const { getFieldState, formState, setValue, watch, trigger } =
    useFormContext();
  const attachment = watch(name);
  const attachmentName = existingAttachment || attachment?.name;
  const { error } = getFieldState(name, formState);
  const { directUploadsUrl } = useEnvironment();
  const { t } = useTranslation('insurance-page');
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles: UploadAttachmentType[]) => {
      const acceptedFile = acceptedFiles[0];
      // FIXME: This doesn't necessarily mean that the file is too large; this
      // message will also show on a drop where the file type isn't supported.
      // https://github.com/purposemed/core/issues/8238
      if (acceptedFile == null) {
        toast.error(t('FileMaxSizeErrorToast'));
        return;
      }
      acceptedFile.key = `${acceptedFile.name}-${Date.now()}`;
      setValue(name, acceptedFile);
      if (deleteFlagName) setValue(deleteFlagName, false);
    },
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    maxSize: 10 * 1024 * 1024, // 10MB to bytes
  });

  useEffect(() => {
    if (!attachment) return;

    const uploader = new Uploader(attachment, directUploadsUrl);
    uploader.upload((blob) => {
      attachment.blob = blob;
    });
    trigger(name);
  }, [attachment, directUploadsUrl, name, trigger]);

  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className="text-grey-500 mb-0 text-sm font-medium"
        >
          {label}
        </label>
      )}

      {existingAttachment || attachment ? (
        <div className="border-grey-200 flex justify-between rounded-lg border p-4">
          {attachmentName && (
            <span className="overflow-hidden text-ellipsis">
              {attachmentName}
            </span>
          )}
          <button
            type="button"
            data-testid="deleteButton"
            className="text-hyperlink-foreground text-base underline"
            onClick={() => {
              if (existingAttachment) setExistingAttachment(null);
              if (deleteFlagName) setValue(deleteFlagName, true);
              setValue(name, null, { shouldValidate: true });
            }}
          >
            {deleteButtonLabel}
          </button>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={clsx(
            'border-grey-400 bg-grey-50 flex flex-col justify-center rounded-lg border border-dashed p-3.5 text-center text-sm',
            isDragActive && 'border-green-500 text-green-500',
            error && 'border-critical-dark',
          )}
        >
          <div className="text-grey-700 text-sm font-medium">{placeholder}</div>
          <input {...getInputProps()} id="attachments" data-testid="dropzone" />
        </div>
      )}
      {error && (
        <p className="text-critical-dark mt-1 border-none text-sm font-medium">
          {error.message}
        </p>
      )}
    </div>
  );
};
