import { useUpdateLabRequisitionMutation } from '@pm/graphql';
import * as Sentry from '@sentry/react';
import {
  ButtonLink,
  detectUserErrors,
  stripDispositionFromUrl,
  useEnvironment,
} from '@pm/core';
import { DownloadIcon } from '@pm/icons';
import { AttachmentFragment } from '@pm/graphql';
import { useTranslation } from 'react-i18next';

export function LabRequisitionAttachments({
  attachments,
  hasBeenViewed,
  variant,
  hasIcon,
  onLinkClick,
  labReqId,
}: {
  attachments: AttachmentFragment[];
  hasBeenViewed: boolean;
  variant: 'primary' | 'secondary' | 'link';
  hasIcon?: boolean;
  onLinkClick?: () => void;
  labReqId: string;
}) {
  const { apiHost } = useEnvironment();
  const { t } = useTranslation('lab-requisitions', {
    keyPrefix: 'LabRequisitions',
  });
  const [updateLabRequisition, { loading }] = useUpdateLabRequisitionMutation();

  const toLabRequisitionUrl = (downloadUrl: string) =>
    stripDispositionFromUrl(`${apiHost}${downloadUrl}`);

  const markAsViewed = async () => {
    try {
      const { data: updateLabRequisitionData } = await updateLabRequisition({
        variables: {
          input: { id: labReqId, viewedAt: new Date().toISOString() },
        },
      });
      detectUserErrors(
        updateLabRequisitionData,
        (res) => res.updateLabRequisition?.errors,
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleLinkClick = async () => {
    if (!loading && !hasBeenViewed) {
      markAsViewed();
    }
    onLinkClick?.();
  };

  return (
    <>
      {attachments.map((attachment, index) => {
        const buttonText =
          attachments.length > 1
            ? t('DownloadNumbered', { count: index + 1 })
            : t('Download');
        if (variant === 'link') {
          return (
            <ButtonLink
              external
              key={attachment.id}
              to={toLabRequisitionUrl(attachment.downloadUrl)}
              onClick={handleLinkClick}
            >
              {buttonText}
            </ButtonLink>
          );
        }
        return (
          <ButtonLink
            external
            key={attachment.id}
            to={toLabRequisitionUrl(attachment.downloadUrl)}
            leftIcon={hasIcon && <DownloadIcon />}
            variant={variant}
            onClick={handleLinkClick}
          >
            {buttonText}
          </ButtonLink>
        );
      })}
    </>
  );
}
