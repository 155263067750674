import {
  QuestionnaireQuestionResource,
  QuestionnaireQuestionResourceTypeEnum,
} from '@pm/graphql';
import { NewWindowIcon } from '@pm/icons';
import { TextLink } from '../TextLink/TextLink';

interface QuestionnaireResourcesProps {
  resources: QuestionnaireQuestionResource[] | null;
}

export const QuestionnaireResources = ({
  resources = [],
}: QuestionnaireResourcesProps) => {
  if (resources === null) {
    return null;
  }

  return resources.map((resource: QuestionnaireQuestionResource) => {
    if (resource.type === QuestionnaireQuestionResourceTypeEnum.Img) {
      return (
        <div key={resource.value} className={'w-full'}>
          <img
            key={resource.value}
            src={resource.value}
            alt={resource.label ?? ''}
          />
        </div>
      );
    } else if (resource.type === QuestionnaireQuestionResourceTypeEnum.Link) {
      return (
        <TextLink
          key={resource.value}
          target="_blank"
          to={resource.value}
          external
        >
          <div className="flex mt-2 items-center gap-1">
            <NewWindowIcon className="h-5 w-5" />
            {resource.label}
          </div>
        </TextLink>
      );
    }
    return;
  });
};
