import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as LabelPrimitive from '@radix-ui/react-label';
import {
  forwardRef,
  useId,
  type ComponentPropsWithoutRef,
  type ElementRef,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { Icon } from '../../Icon/Icon';

type CheckboxProps = Omit<
  ComponentPropsWithoutRef<'button'>,
  'className' | 'id'
> & {
  label?: string;
  indeterminate?: boolean;
  defaultChecked?: boolean;
  onChange?: (state: CheckboxPrimitive.CheckedState) => void;
};

export const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(
  (
    { label, indeterminate, defaultChecked, onChange, ...props },
    forwardedRef,
  ) => {
    const id = useId();

    return (
      <div className="flex items-center">
        <div
          className={twMerge(
            'flex justify-center items-center py-xs px-xxs gap-xs h-[40px] w-[40px]',
            !props.disabled && 'rounded-circle hover:bg-secondary-hover',
          )}
        >
          <CheckboxPrimitive.Root
            {...props}
            id={id}
            defaultChecked={defaultChecked}
            onCheckedChange={(state) => onChange && onChange(state)}
            ref={forwardedRef}
            className={twMerge(
              'justify-center rounded-xs h-[18px] w-[18px] active:h-[24px] active:w-[24px] focus:ring focus:ring-focus',
              props.disabled
                ? 'bg-surface-disabled rdx-state-unchecked'
                : 'rdx-state-checked:bg-primary rdx-state-unchecked:bg-surface rdx-state-unchecked:border-2 rdx-state-unchecked:border-strong',
            )}
          >
            <CheckboxPrimitive.Indicator
              className={twMerge(
                'w-l',
                props.disabled ? 'text-content-disabled' : 'text-surface',
              )}
            >
              <Icon name={indeterminate ? 'remove' : 'check'} size="medium" />
            </CheckboxPrimitive.Indicator>
          </CheckboxPrimitive.Root>
        </div>
        {label && (
          <LabelPrimitive.Label
            htmlFor={id}
            className="text-label text-content"
          >
            {label}
          </LabelPrimitive.Label>
        )}
      </div>
    );
  },
);
Checkbox.displayName = 'Checkbox';
