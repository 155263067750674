import {
  type ReactNode,
  useState,
  createContext,
  useCallback,
  useContext,
} from 'react';
import { createPortal } from 'react-dom';
import {
  Toast as ToastComponent,
  ToastIntentType,
} from '../../components/Toast/Toast';

type Toast = {
  id: string;
  content: string;
  title?: string;
  label?: string;
};

type AddToastArgs = Omit<Toast, 'id'>;

type ToastContextType = {
  positive: ({ ...args }: AddToastArgs) => void;
  negative: ({ ...args }: AddToastArgs) => void;
  info: ({ ...args }: AddToastArgs) => void;
  warning: ({ ...args }: AddToastArgs) => void;
};

type ToastContainerProps = {
  toasts: Toast[];
  onDismissToast: (id: string) => void;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

const ToastContainer = ({ toasts, onDismissToast }: ToastContainerProps) => {
  return createPortal(
    <div>
      {toasts.map(({ id, ...args }) => (
        <ToastComponent
          key={id}
          onOpenChange={() => onDismissToast(id)}
          {...args}
        />
      ))}
    </div>,
    document.body,
  ) as ReactNode;
};

type ToasterProps = {
  children: ReactNode;
};

export const Toaster = ({ children }: ToasterProps) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback(
    ({ ...args }: AddToastArgs & { intent: ToastIntentType }) => {
      const id = crypto.randomUUID();
      setToasts((prevToasts) => [...prevToasts, { ...args, id }]);
    },
    [setToasts],
  );

  const removeToast = useCallback(
    (id: string) =>
      setToasts((prevToasts) =>
        prevToasts.filter((toast: Toast) => toast.id !== id),
      ),
    [setToasts],
  );

  const positive = ({ ...args }: AddToastArgs) => {
    addToast({ ...args, intent: 'positive' });
  };

  const negative = ({ ...args }: AddToastArgs) => {
    addToast({ ...args, intent: 'negative' });
  };

  const info = ({ ...args }: AddToastArgs) => {
    addToast({ ...args, intent: 'info' });
  };

  const warning = ({ ...args }: AddToastArgs) => {
    addToast({ ...args, intent: 'warning' });
  };

  return (
    <ToastContext.Provider value={{ positive, negative, info, warning }}>
      <ToastContainer toasts={toasts} onDismissToast={removeToast} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
};
