import { forwardRef } from 'react';
import { type VariantProps, cva } from 'class-variance-authority';

/**
 * Secure message specific row to be used with Table.
 */

const messageRow = cva(
  'flex flex-row h-[80px] items-center border-t border-b px-m py-l',
);

type MessageProps = {
  subject: string;
  message: string;
  senderName: string;
  sentDate: string;
  read?: boolean;
};

type MessageRowProps = MessageProps & VariantProps<typeof messageRow>;

export const MessageRow = forwardRef<HTMLTableRowElement, MessageRowProps>(
  ({ subject, message, senderName, sentDate, ...props }, ref) => {
    return (
      <tr ref={ref} className={messageRow()} {...props}>
        <td className="flex flex-col gap-xxs">
          <p>{subject}</p>
          <p>{senderName}</p>
        </td>
        <td className="ml-auto">{sentDate}</td>
      </tr>
    );
  },
);
MessageRow.displayName = 'MessageRow';
