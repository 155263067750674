import clsx from 'clsx';
import { useToggle } from '../../hooks/use-toggle/useToggle';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect, useRef, useState } from 'react';

export interface ReadMoreProps {
  text: string;
  lines?: 1 | 2 | 3;
}

export const ReadMore = ({ lines = 2, text }: ReadMoreProps) => {
  const { t } = useTranslation('core', {
    keyPrefix: 'components.readMore.actions',
  });
  const ref = useRef<HTMLParagraphElement>(null);
  const [expanded, { toggle }] = useToggle(false);
  const [showReadMore, setShowReadMore] = useState(false);

  useLayoutEffect(() => {
    const evaluateHeight = () => {
      if (ref.current) {
        const { scrollHeight, clientHeight } = ref.current;
        const canShowMore = scrollHeight > clientHeight;
        return canShowMore;
      }

      return false;
    };

    const canShowMore = evaluateHeight();
    setShowReadMore(canShowMore);
  }, [text]);

  return (
    <>
      <p
        ref={ref}
        className={clsx(
          !expanded && {
            'line-clamp-1': lines === 1,
            'line-clamp-2': lines === 2,
            'line-clamp-3': lines === 3,
          },
        )}
      >
        {text}
      </p>
      {showReadMore && (
        <button
          type="button"
          onClick={toggle}
          className="text-brand-primary-500 underline"
          aria-expanded={expanded}
        >
          {expanded ? t('close') : t('open')}
        </button>
      )}
    </>
  );
};
