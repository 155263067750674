import {
  addDays,
  addMinutes,
  addWeeks,
  differenceInCalendarDays,
  differenceInCalendarWeeks,
  differenceInHours,
  differenceInYears,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  formatISO,
  getDaysInMonth,
  getMonth as getDateMonth,
  parse,
  parseISO,
  startOfMonth,
  startOfWeek,
  Duration,
  add,
  isAfter,
} from 'date-fns';
import i18next from 'i18next';
import {
  FormattingContext,
  ISO8601Date,
  ISO8601DateTime,
  SupportedLanguage,
} from '../types';
import { keyBy } from './array';
import enCA from 'date-fns/locale/en-CA/index.js';
import frCA from 'date-fns/locale/fr-CA/index.js';

const LOCALES: Record<SupportedLanguage, Locale> = { en: enCA, fr: frCA };

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const arrayFrom = (num: number): number[] => {
  return [...Array(num).keys()];
};

const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) =>
    (start + i * step).toString(),
  );

const currentYear = new Date().getFullYear();
export const years = range(currentYear, currentYear - 100, -1);
export const days = range(1, 31, 1);
export const months = () => {
  let locale = enCA;
  if (i18next.language === 'fr') {
    locale = frCA;
  }
  return [
    format(new Date(0, 1, 0), 'MMMM', { locale }),
    format(new Date(0, 2, 0), 'MMMM', { locale }),
    format(new Date(0, 3, 0), 'MMMM', { locale }),
    format(new Date(0, 4, 0), 'MMMM', { locale }),
    format(new Date(0, 5, 0), 'MMMM', { locale }),
    format(new Date(0, 6, 0), 'MMMM', { locale }),
    format(new Date(0, 7, 0), 'MMMM', { locale }),
    format(new Date(0, 8, 0), 'MMMM', { locale }),
    format(new Date(0, 9, 0), 'MMMM', { locale }),
    format(new Date(0, 10, 0), 'MMMM', { locale }),
    format(new Date(0, 11, 0), 'MMMM', { locale }),
    format(new Date(0, 12, 0), 'MMMM', { locale }),
  ];
};
export const monthNumbers = range(1, 12, 1);

export const getYear = (date: string | null) => {
  if (!date) {
    return '';
  }
  const tokens = date.split('-');
  if (tokens.length < 3) {
    return '';
  }
  return tokens[0];
};

export const getMonth = (date: string | null, monthFormat?: string | null) => {
  if (!date) {
    return '';
  }
  const tokens = date.split('-');
  if (tokens.length < 3) {
    return '';
  }
  if (monthFormat) {
    return format(
      new Date(Number(tokens[0]), Number(tokens[1]) - 1, Number(tokens[2])),
      monthFormat,
    );
  }
  return Number(tokens[1]).toString();
};

export const getDay = (date: string | null) => {
  if (!date) {
    return '';
  }
  const tokens = date.split('-');
  if (tokens.length < 3) {
    return '';
  }
  return Number(tokens[2]).toString();
};

export const makeDateString = (
  year: string,
  month: string,
  day: string,
  monthFormat = 'MMM',
) => {
  if (month.length > 2) {
    const convertedDate = parse(
      `${day}-${month}-${year}`,
      `dd-${monthFormat}-yyyy`,
      new Date(),
    );
    month = (getDateMonth(convertedDate) + 1).toString();
  }

  const paddedMonth = month.length === 1 ? `0${month}` : month;
  const paddedDay = day.length === 1 ? `0${day}` : day;
  return `${year}-${paddedMonth}-${paddedDay}`;
};

export const toISO8601Date = (date: Date) =>
  formatISO(date, { representation: 'date' });

export const getEpochWeek = (date: Date) =>
  differenceInCalendarWeeks(date, new Date(0));

export const setEpochWeek = (epochWeek: number) =>
  addWeeks(new Date(0), epochWeek);

export const getEpochDay = (date: Date) =>
  differenceInCalendarDays(date, new Date(0));

export const setEpochDay = (epochDay: number) => addDays(new Date(0), epochDay);

export const isUserEighteen = (date: Date) => {
  const age = differenceInYears(new Date(), new Date(date));
  return age >= 18;
};

export const previousMonth = (date: Date): Date => {
  const prevMonth = new Date(date);
  prevMonth.setMonth(date.getMonth() - 1);
  return prevMonth;
};

export const daysInMonthArray = (date: Date): Date[] => {
  const numDays = getDaysInMonth(date);
  return arrayFrom(numDays).map((indexInMonth) => {
    const dayInMonth = indexInMonth + 1;
    return new Date(date.getFullYear(), date.getMonth(), dayInMonth);
  });
};

const getEndOfPreviousMonth = (currentDate: Date) => {
  const startOffset = startOfMonth(currentDate).getDay();

  if (startOffset === 0) {
    return [];
  }

  const previousMonthArray = daysInMonthArray(previousMonth(currentDate));
  return previousMonthArray.slice(-startOffset);
};

export const createMonthView = (date: Date) => {
  const numberOfDaysInWeek = 7;
  const maxWeeksSpannedInMonth = 6;
  const numCells = numberOfDaysInWeek * maxWeeksSpannedInMonth;

  const days = daysInMonthArray(date);
  const endOfPreviousMonth = getEndOfPreviousMonth(date);

  const endOffset = numCells - days.length - endOfPreviousMonth.length;
  const startOfNextMonth = arrayFrom(endOffset).map((i) => {
    const dayInMonth = i + 1;
    return new Date(date.getFullYear(), date.getMonth() + 1, dayInMonth);
  });

  return [...endOfPreviousMonth, ...days, ...startOfNextMonth];
};

type WeekStartsOnOptions = NonNullable<
  Parameters<typeof startOfWeek>[1]
>['weekStartsOn'];

export const buildMonthMatrix = (
  date: Date,
  weekStartsOn: WeekStartsOnOptions = 0,
) => {
  return eachDayOfInterval({
    start: startOfWeek(startOfMonth(date), { weekStartsOn }),
    end: endOfWeek(endOfMonth(date), { weekStartsOn }),
  });
};

export const getFirstDayOfNextMonth = (date: Date): Date => {
  const dateClone = new Date(date);
  return new Date(dateClone.setMonth(dateClone.getMonth() + 1));
};

export const getChangeInMonths = (monthDifference = 0, date?: Date): Date => {
  if (!date) {
    date = new Date();
  }
  const dateClone = new Date(date);
  return new Date(dateClone.setMonth(dateClone.getMonth() + monthDifference));
};

export const formatDateAmericanFormat = (date: string, includeTime = false) => {
  const dateAsISO = parseISO(date);

  if (includeTime) {
    return format(dateAsISO, 'MMM-dd-yyyy HH:mm');
  }
  return format(dateAsISO, 'MMM-dd-yyyy');
};

export const canCancelOrRescheduleAppointment = (
  scheduledAt: string,
): boolean => {
  return differenceInHours(new Date(scheduledAt), new Date()) >= 24;
};

export const formatTimeInterval = (date: Date, durationInMinutes: number) => {
  const startPeriod = formatDate(date, undefined, {
    dayPeriod: 'short',
  });
  const endPeriod = formatDate(addMinutes(date, durationInMinutes), undefined, {
    dayPeriod: 'short',
  });

  const endTime = formatDate(addMinutes(date, durationInMinutes), undefined, {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  });

  if (startPeriod !== endPeriod) {
    const startTimeWithAMPM = formatDate(date, undefined, {
      hour: 'numeric',
      minute: 'numeric',
    });
    return `${startTimeWithAMPM} - ${endTime}`;
  }

  const startTimeWithoutAMPM = format(date, 'h:mm');
  return `${startTimeWithoutAMPM} - ${endTime}`;
};

/**
 * Used to consistently parse dates from the API
 */
export const parseISODate = (date: ISO8601Date | ISO8601DateTime) =>
  parseISO(date);

const dobFormatContextByLocale: Record<
  FormattingContext,
  Record<SupportedLanguage, string>
> = {
  patient: {
    en: 'MMMM dd, yyyy',
    fr: 'dd MMMM yyyy',
  },
  provider: {
    en: 'yyyy-MM-dd',
    fr: 'yyyy-MM-dd',
  },
};

/**
 * Used to format date of birth (DOB) values from the API
 * @example 2020-01-01
 */
export const formatDateOfBirth = (
  dobDate: ISO8601Date,
  formattingContext: FormattingContext = 'provider',
  locale: SupportedLanguage = 'en',
) => {
  const displayFormat = dobFormatContextByLocale[formattingContext][locale];
  return format(parseISO(dobDate), displayFormat, {
    locale: LOCALES[locale],
  });
};

export const formatDate = (
  date: Date,
  locale: SupportedLanguage = 'en',
  formatOptions: Intl.DateTimeFormatOptions,
) => {
  if (formatOptions.timeZoneName) {
    const timeZone = new Intl.DateTimeFormat(
      locale,
      formatOptions,
    ).resolvedOptions().timeZone;
    formatOptions = { ...formatOptions, timeZone };
  }
  return date.toLocaleString(locale, formatOptions);
};

export const formatDateToShortFormat = (
  date: Date,
  locale = 'en',
  includeTime = false,
) => {
  const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

  const dateFormatter = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
    timeZoneName: 'short',
  });

  const { day, month, year, hour, minute, dayPeriod, timeZoneName } = keyBy(
    dateFormatter.formatToParts(date),
    'type',
  );

  const dateFormat = [day.value, month.value, year.value];
  const timeFormat = [hour.value, minute.value];

  return `${dateFormat.join('-')} ${
    includeTime
      ? ` at ${timeFormat.join(':')} ${dayPeriod.value} ${timeZoneName.value}`
      : ''
  }`.trim();
};

export const formatDateToLongForm = (
  date: Date,
  locale: SupportedLanguage = 'en',
) => {
  return formatDate(date, locale, {
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  });
};

export const formatDateToLongFormYear = (
  date: Date,
  locale: SupportedLanguage = 'en',
  weekDayFormat?: Intl.DateTimeFormatOptions['weekday'],
) => {
  return formatDate(date, locale, {
    weekday: weekDayFormat,
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

export const formatTimeTo12HourWithZone = (
  date: Date,
  locale: SupportedLanguage = 'en',
) => {
  return formatDate(date, locale, {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  });
};

export const format24HourTime = (
  date: Date,
  locale: SupportedLanguage = 'en',
) => {
  return formatDate(date, locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
};

export const getWeekdayStrings = (options?: {
  locale?: SupportedLanguage;
  weekdayFormat?: 'long' | 'short';
  weekStartsOn?: WeekStartsOnOptions;
}) => {
  const {
    locale = 'en',
    weekdayFormat = 'long',
    weekStartsOn = 0,
  } = options || {};
  const now = new Date();

  return eachDayOfInterval({
    start: startOfWeek(now, { weekStartsOn }),
    end: endOfWeek(now, { weekStartsOn }),
  }).map((date) => formatDate(date, locale, { weekday: weekdayFormat }));
};

export function isOutsideTimeWindow(date: Date, duration: Duration) {
  const now = new Date();
  return isAfter(date, add(now, duration));
}
