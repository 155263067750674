import { ApolloQueryResult } from '@apollo/client/index.js';
import { GetMeQuery, useGetMeQuery } from '@pm/graphql';
import * as Sentry from '@sentry/react';
import { useLDClient } from '@mikecousins/launchdarkly-react-client-sdk';
import { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type ProfileContextType = {
  profile: GetMeQuery['me'];
  refetch: () => Promise<ApolloQueryResult<GetMeQuery>>;
  loading: boolean;
};

export const ProfileContext = createContext<ProfileContextType | undefined>(
  undefined,
);

export const useProfileContext = () => useContext(ProfileContext);

export const ProfileProvider = ({ children }: PropsWithChildren) => {
  const LDClient = useLDClient();
  const { i18n } = useTranslation();
  const { data, refetch, loading } = useGetMeQuery();

  const me = data?.me;

  useEffect(() => {
    if (me) {
      // set our language
      i18n.changeLanguage(me.locale);

      // set sentry user
      Sentry.configureScope((scope) => {
        scope.setUser({ id: me.id });
      });

      // set launch darkly user
      LDClient?.identify({ key: me.id });
    }
  }, [LDClient, i18n, me]);

  // set segment user
  useEffect(() => {
    if (me && window.analytics && window.analytics.identify) {
      window.analytics.identify(me.id, {
        role: me.role.code,
        email: me.email,
      });
    }
  }, [me]);

  if (!me) {
    return null;
  }

  return (
    <ProfileContext.Provider
      value={{
        profile: data.me,
        refetch,
        loading,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
