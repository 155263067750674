import { ActionVariantEnum, QuestionnaireOutcomeFragment } from '@pm/graphql';
import { ButtonLink, ButtonProps } from '@pm/ui';
import { Card } from '../Card';
import { QuestionnairePageHeading } from './QuestionnairePageHeading';
import { QuestionnaireSection } from './QuestionnaireSection';

type OutcomeAction = {
  label: string;
  variant: ActionVariantEnum;
  to: string;
};

type StepProps<T> = {
  step: T;
  actions: OutcomeAction[];
};

const actionVariantToIntent: Record<ActionVariantEnum, ButtonProps['intent']> =
  {
    [ActionVariantEnum.Primary]: 'primary',
    [ActionVariantEnum.Secondary]: 'secondary',
  };

export const OutcomePage = ({
  step,
  actions,
}: StepProps<QuestionnaireOutcomeFragment>) => {
  const { title, description } = step;
  const isTitleEmpty = !title;
  const isDescriptionEmpty = !description;
  const showHeading = !isTitleEmpty || !isDescriptionEmpty;

  return (
    <>
      <Card className="select-none space-y-xxxl">
        {showHeading && (
          <QuestionnaireSection>
            <QuestionnairePageHeading
              title={title as string}
              description={description as string}
            />
          </QuestionnaireSection>
        )}
        <div className="gap-y-l flex flex-col">
          {actions.map(({ label, variant, to }) => (
            <ButtonLink
              key={label}
              to={to}
              intent={actionVariantToIntent[variant]}
            >
              {label}
            </ButtonLink>
          ))}
        </div>
      </Card>
    </>
  );
};
