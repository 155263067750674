import { type QuestionnaireRadioQuestion as GQLQuestionnaireRadioQuestion } from '@pm/graphql';
import { CardText } from '../CardText';
import { HookFormSelect } from '../Select/HookFormSelect';
import { QuestionnaireSection } from './QuestionnaireSection';
import { QuestionnaireMarkdown } from '../QuestionnaireMarkdown/QuestionnaireMarkdown';

type QuestionnaireRadioGroupQuestionProps = GQLQuestionnaireRadioQuestion;

export const QuestionnaireRadioGroupQuestion = ({
  id,
  acceptableAnswers = [],
  title,
  description,
}: QuestionnaireRadioGroupQuestionProps) => {
  const options = acceptableAnswers.map((acceptableAnswer) => ({
    text: acceptableAnswer.label,
    value: acceptableAnswer.value ?? '',
  }));

  return (
    <QuestionnaireSection>
      <CardText className="text-grey-900 text-xl">{title}</CardText>
      {description && (
        <CardText>
          <QuestionnaireMarkdown>{description}</QuestionnaireMarkdown>
        </CardText>
      )}
      <HookFormSelect name={id} options={options} />
    </QuestionnaireSection>
  );
};
