import { format as dateFormat, parseISO } from 'date-fns';
import enCA from 'date-fns/locale/en-CA/index.js';
import frCA from 'date-fns/locale/fr-CA/index.js';
import { useTranslation } from 'react-i18next';

type DateTimeProps = {
  value: string;
  format: string;
};

export const DateTime = ({ value, format }: DateTimeProps) => {
  const { i18n } = useTranslation();
  let locale = enCA;
  if (i18n.language === 'fr') {
    locale = frCA;
  }

  return <>{dateFormat(parseISO(value), format, { locale })}</>;
};
