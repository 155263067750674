import { VariantProps, cva } from 'class-variance-authority';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { WithIcon } from '../../types/component.types';

export const button = cva(
  'rounded-s text-content-inverse focus:ring outline-none aria-disabled:bg-surface-disabled aria-disabled:text-content-disabled aria-disabled:cursor-not-allowed inline-flex items-center justify-center',
  {
    variants: {
      intent: {
        primary: 'bg-primary hover:bg-primary-hover active:bg-primary-active',
        secondary:
          'bg-secondary border border-strong text-content-subdued hover:bg-secondary-hover active:bg-secondary-active',
        ghost:
          'text-content-link bg-transparent hover:bg-secondary-hover active:bg-secondary-active',
        destructive:
          'bg-negative text-content-inverse hover:bg-negative-hover active:bg-negative-active ring-negative',
      },
      size: {
        large: 'py-s px-xl h-l text-button-l gap-x-s',
        small: 'py-xs px-m h-s text-button-s gap-x-xs',
      },
      width: {
        full: 'w-full',
        fit: 'w-fit',
      },
      // TODO: Remove `purpose` since we have a `ButtonIcon` component for icons.
      purpose: {
        trigger: 'aria-expanded:ring',
        icon: '',
      },
    },
    compoundVariants: [
      {
        intent: 'ghost',
        size: 'large',
        class: '!px-s',
      },
      {
        intent: 'ghost',
        size: 'small',
        class: '!px-xs',
      },
      {
        purpose: 'icon',
        size: 'large',
        class: '!px-s',
      },
      {
        purpose: 'icon',
        size: 'small',
        class: '!px-xs',
      },
      {
        purpose: 'icon',
        intent: 'ghost',
        class: '!text-content',
      },
    ],
    defaultVariants: {
      intent: 'primary',
      size: 'large',
    },
  },
);

export type ButtonProps = VariantProps<typeof button> &
  Omit<ComponentPropsWithoutRef<'button'>, 'className'> &
  WithIcon;

/**
 * Basic buttons are default buttons with text labels. Use sentence case for all button labels. A basic button can have text label only, with a leading icon, or with a trailing icon.
 *
 * @example
 * ```tsx
 * <Button intent="secondary">Click me!</Button>
 * ```
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      intent,
      purpose,
      size,
      width,
      type = 'button',
      leadingIcon,
      trailingIcon,
      children,
      disabled,
      onClick,
      ...buttonProps
    },
    ref,
  ) => {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        ref={ref}
        className={button({ intent, purpose, size, width })}
        aria-disabled={disabled}
        onClick={disabled ? (e) => e.preventDefault() : onClick}
        {...buttonProps}
      >
        {leadingIcon}
        {children}
        {trailingIcon}
      </button>
    );
  },
);

Button.displayName = 'Button';
